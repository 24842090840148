// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/favicon.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Ultra Red",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/CreativeTim/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/creativetim",
    },
  ],
  menus: [
    {
      name: "Empresa",
      items: [
        { name: "Inicio", route: "/home" },
        { name: "Tarifas", route: "/tarifas" },
        { name: "Quienes Somos", route: "/quienes-somos" },
        { name: "Contactanos", route: "/contacto" },
      ],
    },
    {
      name: "Informacion Adicional",
      items: [
        {
          name: "Contrato de tarifas",
          href: `${window.location.origin}/contrato_2024.pdf`,
        },
        {
          name: "Guía de Seguridad y Control de Internet",
          href: `${window.location.origin}/CONSEJOS-DE-SEGURIDAD-DE-INTERNET.pdf`,
        },
        {
          name: "Reglamentos Abonados",
          href: `${window.location.origin}/reglamentos-abonados.pdf`,
        },
        {
          name: "Ley Organica de Telecomunicaciones",
          href: `${window.location.origin}/ley_organica_de_telecomunicaciones.pdf`,
        },
        {
          name: "Anexos de contrato",
          href: `${window.location.origin}/anexos_servicios_internet.pdf`,
        },
        {
          name: "Control Parental",
          href: `${window.location.origin}/control-parental.pdf`,
        },
        {
          name: "Glosario de Terminos",
          href: `${window.location.origin}/GLOSARIO-DE-TERMINOS.pdf`,
        },
        {
          name: "Parametros de Calidad",
          href: `${window.location.origin}/PARAMETROS_DE_CALIDAD.pdf`,
        },
        {
          name: "Terminologias",
          href: `${window.location.origin}/terminologias.pdf`,
        },
        {
          name: "Saturacion",
          href: `${window.location.origin}/Saturacion.pdf`,
        },
        {
          name: "Test de Internet",
          href: `https://www.speedtest.net/`,
        },
      ],
    },
    {
      name: "Enlaces Gubernamentales",
      items: [
        {
          name: "Arcotel",
          href: "https://www.arcotel.gob.ec/",
        },
        {
          name: "Mintel",
          href: "https://www.telecomunicaciones.gob.ec/",
        },
        {
          name: "Presidencia de la republica",
          href: "https://www.presidencia.gob.ec/",
        },
      ],
    },
    {
      name: "Leyes",
      items: [
        {
          name: "LEY ORGÁNICA DE LAS PERSONAS ADULTAS MAYORES (LOPAM)",
          href: `${window.location.origin}/LEY-ORGANICA-DE-LAS-PERSONAS-ADULTAS-MAYORES.pdf`,
        },
        {
          name: "Politica publica de internet segura",
          href: `${window.location.origin}/politica_publica_internet_segura.pdf`,
        },
        {
          name: "Reglamento del abonado",
          href: `${window.location.origin}/reglamento-abonado.pdf`,
        },
        {
          name: "Reglamento Ley Organica de Telecomunicaciones",
          href: `${window.location.origin}/Reglamento-Ley-Organica-de-Telecomunicaciones.pdf`,
        },
        {
          name: "Reglamento de Tercera Edad",
          href: `${window.location.origin}/TERCERA_EDAD.pdf`,
        },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      Todos los derechos reservados. Copyright &copy; {date}
    </MKTypography>
  ),
};
